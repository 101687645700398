<template>
  <v-container fluid class="table-model">
    <v-form ref="arrayForm">
      <v-simple-table dense>
        <template v-slot:default>
          <thead class="table-model-headers">
            <tr>
              <th></th>
              <th
                v-for="(column, index) in columns"
                :key="index"
                :data-test-id="'array_header_' + column"
                class="text-center"
              >
                {{ column }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="!itemUimodels || Object.keys(itemUimodels).length === 0"
              class="table-model-item"
            >
              <td :colspan="columns.length + 2">
                <div class="d-flex justify-center align-center text--disabled">
                  No items found
                </div>
              </td>
            </tr>
            <tr
              v-else
              v-for="(item, index) in itemUimodels"
              :key="index"
              class="table-model-item"
              :data-test-id="'array_item_' + uimodel.path + '/' + index"
            >
              <td>{{ index }}</td>

              <td v-if="!isNonPrimitive(item) || item.possibleSchemas">
                <!-- Non-primitve item, which can be expanded (array, object)-->
                <v-container
                  v-if="isNonPrimitive(item)"
                  fluid
                  class="expandable-item-row"
                >
                  <v-container
                    class="expand-btn-container"
                    :data-test-id="'expand_btn_' + item.path"
                    @click="toggleExpand(item.property)"
                  >
                    <v-icon v-if="expanded[item.property]"
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon v-else>mdi-chevron-right</v-icon>

                    <div class="expand-preview">
                      {{ JSON.stringify(item.data || item.inheritedData) }}
                    </div>
                  </v-container>
                  <v-container
                    v-if="expanded[item.property]"
                    class="expanded-editor-container"
                  >
                    <DataEditor
                      :uimodel="item"
                      :show-label="false"
                      :disabled="disabled"
                      @input="setProperty(index, item, $event)"
                      @restore="restoreProperty(item, data)"
                    />
                  </v-container>
                </v-container>

                <!-- Primitve item (string, number, booelean) -->
                <DataEditor
                  v-else
                  :uimodel="item"
                  :show-label="false"
                  :disabled="disabled"
                  @input="setProperty(index, item, $event)"
                  @restore="restoreProperty(item, data)"
                />
              </td>

              <td
                v-else
                v-for="propertyModel in getPropertyUiModels(item)"
                :key="propertyModel.property"
                class="table-model-column"
              >
                <v-container v-if="isNonPrimitive(propertyModel)" fluid>
                  <v-btn
                    icon
                    :color="hasViolation(propertyModel) ? 'error' : undefined"
                    :data-test-id="'open_dialog_btn_' + propertyModel.path"
                    @click.stop="openDialog(propertyModel, index)"
                  >
                    <v-icon> mdi-open-in-new</v-icon>
                  </v-btn>
                  <v-icon
                    v-if="hasViolation(propertyModel)"
                    :key="violations.length"
                    :data-test-id="'violation_alert_' + propertyModel.path"
                    color="error"
                  >
                    mdi-alert
                  </v-icon>
                </v-container>

                <DataEditor
                  v-else
                  :uimodel="propertyModel"
                  :show-label="false"
                  :disabled="disabled"
                  @input="setProperty(index, propertyModel, $event)"
                  @restore="restoreProperty(propertyModel, data[index])"
                />
              </td>
              <td>
                <v-btn
                  v-if="showDeleteBtn"
                  icon
                  color="red"
                  :data-test-id="index + '_delete_btn'"
                  @click="removeItem(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="showAddBtn">
              <td :colspan="columns.length + 2" class="table-model-add-row">
                <v-btn
                  v-if="!disabled"
                  text
                  color="primary"
                  class="table-model-add-btn"
                  data-test-id="addItemBtn"
                  @click="addItem"
                >
                  <v-icon left>mdi-plus</v-icon> Add Item
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- Show objects and arrays in a popup dialog -->
      <v-dialog v-model="dialog">
        <v-card
          class="data-editor-card"
          v-if="dialog"
          data-test-id="complexTypeDialog"
        >
          <v-toolbar flat>
            <v-toolbar-title>{{ getLabel(dialogModel) }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              data-test-id="complexTypeDialogCloseBtn"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <DataEditor
            :uimodel="dialogModel"
            :show-label="false"
            :disabled="disabled"
            @input="setProperty(dialogIndex, dialogModel, $event)"
            @restore="restoreInDialog(dialogModel, data, dialogIndex)"
          />
        </v-card>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import mixin from "../../../../mixins/data-editor-mixin";

export default {
  mixins: [mixin],

  inject: {
    getViolations: {
      default() {
        console.warn("Parent does not provide getViolations function");
        return () => {
          return [];
        };
      },
    },
  },

  components: {
    DataEditor: () => import("./DataEditor.vue"),
  },

  props: {
    //the array ui model
    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return { domainData: null };
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      update: 0,
      data: this.uimodel?.data,
      dialog: false,
      dialogModel: {},
      dialogIndex: null,
      expanded: {},
      timeout: 0,
    };
  },

  watch: {
    data(data) {
      // we prevent emitting when the parent is already undefined.
      // this also prevents a cycle where setting an object to inherit causes children to emit and the object comes back.
      if (data === undefined && this.uimodel.parentData === undefined) {
        return;
      }
      const property = this.uimodel.property;
      const existingData = this.uimodel?.parentData?.[property];
      if (this.$isEqual(existingData, data)) return;
      this.$emit("input", data);
    },

    "uimodel.data": function () {
      this.data = this.uimodel.data;
    },

    update() {
      if (this.dialog) {
        //if the table model is updated,
        //also update the model in the currently open dialog
        const property = this.dialogModel.property;
        const itemUiModel = this.itemUimodels[this.dialogIndex];

        if (itemUiModel.schema?.properties) {
          let uimodel = this.getPropertyUiModels(itemUiModel)?.[property];
          if (uimodel !== undefined) {
            this.dialogModel = Object.assign({}, uimodel);
          } else {
            this.dialog = false;
            this.dialogModel = {};
          }
        } else {
          if (this.isNonPrimitive(itemUiModel.data)) {
            this.dialog = false;
            this.dialogModel = {};
          } else {
            this.dialogModel = Object.assign({}, itemUiModel);
          }
        }
      }

      this.validate();
    },
  },

  methods: {
    getPropertyUiModels(itemUiModel) {
      let models = this.buildSubUimodels(itemUiModel);

      for (let p in models) {
        this.$set(models[p], "isArrayInherited", this.inherited);
      }

      return models;
    },

    isNonPrimitive(uimodel) {
      const type = this.getUiModelType(uimodel);
      return type === "object" || type === "array";
    },

    toggleExpand(property) {
      if (this.expanded[property] === true) {
        this.$delete(this.expanded, property);
        return;
      }
      this.$set(this.expanded, property, true);
    },

    setProperty(index, sub, val) {
      // if a sub property wants to exist, we need to ensure that this.data can take it
      if (this.data === null) this.data = [];
      const dataType = this.$getType(val);

      //close open dialog if the data type changed while it was open
      //and it is not an object or array
      if (dataType !== "object" && dataType !== "array" && this.dialog) {
        this.dialog = false;
      }

      //if the current data is an array and the property of the sub-model is an array index,
      //set the whole array item to the given value
      if (Array.isArray(this.data) && !isNaN(Number(sub.property))) {
        this.$set(this.data, index, val);
      } else {
        this.$set(this.data[index], sub.property, val);
      }

      this.update++;
    },

    addItem() {
      if (!this.data) this.data = [];
      const schema = this.uimodel.schema;

      if (!schema?.items) {
        this.data.push("");
      } else {
        const type = schema?.items?.type;
        if (type === "object") {
          //if schema items are of type object,
          //create an shallow object with all column properties
          let obj = {};
          const properties = schema.items?.properties
            ? Object.keys(schema.items?.properties)
            : [];
          properties.forEach((col) => this.$set(obj, col, null));
          this.data.push(obj);
        } else {
          //else just push an empty value
          this.data.push(this.getInitialData(type));
        }
      }

      this.update++;
    },

    removeItem(index) {
      this.$delete(this.data, index);
      this.update++;
    },

    openDialog(uimodel, index) {
      this.dialogIndex = index;
      this.dialogModel = uimodel;
      this.dialog = true;
    },

    restoreInDialog(uimodel, data, index) {
      if (uimodel.parentData && Array.isArray(uimodel.parentData)) {
        //the model to restore is the whole array item, so restore the row
        this.restoreProperty(uimodel, data);
        return;
      }

      this.restoreProperty(uimodel, data[index]);
    },

    getLabel(uimodel) {
      //use schema titel as default label
      let label = uimodel?.schema?.title?.values?.["en-GB"];
      //if no title, fallback to property name
      if (label === undefined) label = uimodel?.property;
      return label;
    },

    hasViolation(uimodel) {
      const path = uimodel.path?.replaceAll("/", ".");
      return this.violations.some(({ property }) => property.startsWith(path));
    },

    validate() {
      /* Wait static amount of time, since on $nextTick not all
      components may be rendered */
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        const form = this.$refs.arrayForm;
        this.$validateVForm(form);
      }, 100);
    },
  },

  computed: {
    columns() {
      const schema = this.uimodel.schema;
      const properties = schema?.items?.properties;
      if (properties) {
        const required = schema.items?.required ?? [];
        //use the schema title or name of each property as column title
        let columns = [];
        for (let p in properties) {
          const propertySchema = properties[p];
          const title = propertySchema?.title?.values?.["en-GB"] ?? p;
          const isRequired = required.includes(p);
          columns.push(title + (isRequired ? "*" : ""));
        }
        return columns;
      }
      //if the items are no complex types, add one column to display the input fields
      if (!schema?.items || schema?.items?.type) return new Array(1);

      return [];
    },

    itemUimodels() {
      this.update;
      let uimodels = this.buildSubUimodels(this.uimodel);
      if (this.$isPlainObject(uimodels)) {
        Object.values(uimodels).forEach((model) => {
          this.$set(model, "editable", model.editable && !this.inherited);
        });
      }
      return uimodels;
    },

    inherited() {
      const isInherited =
        this.uimodel.inheritable &&
        (this.data === undefined || this.data === null);

      const hasParent = !!this.uimodel.parentSchema?.type;
      const parentInherited =
        hasParent &&
        (this.uimodel.parentData === null ||
          this.uimodel.parentData === undefined);
      return isInherited || parentInherited;
    },

    isEditableFromSchema() {
      return this.uimodel.editable;
    },

    reachedMaxItems() {
      this.update;
      return (
        this.uimodel.schema?.maxItems &&
        this.uimodel?.data?.length === this.uimodel.schema?.maxItems
      );
    },

    reachedMinItems() {
      this.update;
      return (
        this.uimodel.schema?.maxItems &&
        this.uimodel.data.length === this.uimodel.schema?.minItems
      );
    },

    showAddBtn() {
      const isNotInherited =
        !this.inherited ||
        (!this.uimodel.inheritable && !!this.uimodel.parentData);
      return (
        this.uimodel.schema &&
        isNotInherited &&
        !this.disabled &&
        this.isEditableFromSchema &&
        !this.reachedMaxItems
      );
    },

    showDeleteBtn() {
      return (
        !this.inherited &&
        this.isEditableFromSchema &&
        !this.reachedMinItems &&
        !this.disabled
      );
    },

    violations() {
      const path = this.uimodel.path?.replaceAll("/", ".");
      return this.getViolations(null, { returnObject: true }).filter(
        (violation) => {
          return violation?.property?.startsWith(path);
        }
      );
    },
  },
};
</script>

<style scoped>
.table-model-headers {
  text-align: center;
}

.table-model-headers > th {
  height: 36px;
}

.table-model-item > td.table-model-column {
  vertical-align: top;
  height: fit-content;
  padding: 0;
}

.table-model-column > .container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

td.table-model-add-row {
  padding: 0 !important;
}

.table-model-add-btn.v-btn {
  width: 100%;
  padding: 0;
}

.data-editor-card {
  padding: 12px;
}

.table-model .expandable-item-row {
  display: flex;
  flex-flow: column;
}

.expandable-item-row > .container {
  margin: 0;
}

.expandable-item-row .expand-btn-container > .expand-preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.table-model .expandable-item-row > .expand-btn-container {
  width: auto;
  display: flex;
  cursor: pointer;
}

.expanded-editor-container::v-deep > .object:first-child > div > .controls,
.expanded-editor-container::v-deep > .array:first-child > div > .controls {
  top: -27px;
}

.expanded-editor-container::v-deep
  > .object:first-child
  > div.json-editor-container,
.expanded-editor-container::v-deep
  > .array:first-child
  > div.json-editor-container {
  padding-top: inherit;
}

.data-editor-card::v-deep > .object > div > .controls,
.data-editor-card::v-deep > .array > div > .controls {
  top: -20px !important;
}

.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.table-model-item:hover {
  background: inherit !important;
}

.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.table-model-item
  > .table-model-column
  .field-wrap {
  width: 300px;
}

.array
  .table-model-column
  > .data-editor::v-deep
  > .primitive-type-container
  > .custom
  > .data-editor
  > .primitive-type-container {
  justify-content: center;
  padding: 4px;
}
</style>

<style>
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.table-model-item
  > .table-model-column
  .field-wrap
  > .v-select
  .v-select__selections {
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>